import React, { useRef, useState, useEffect } from 'react';
import { renderRichText } from "gatsby-source-contentful/rich-text";
import Seo from "../../components/common/seo";
import Layout from "../../components/common/layout";
import { Link, graphql } from "gatsby";
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import moment from 'moment';

const ResourceDetail = ({ data }) => {
  function monthYearDate(dateString) {
    return moment(dateString).format('MMM YYYY');
  }

  function dayDate(dateString) {
    return moment(dateString).format('DD');
  }

  const resource = data.contentfulResourceCentre || {}; // Default to empty object
  const resources = data.allContentfulResourceCentre.nodes || []; // Default to empty array
  const locale = resource.node_locale || "en-CA"; // Default to English locale

  // Check if title exists; if not, default to "Untitled Resource"
  const resourceTitle = resource.title || "Untitled Resource";

  const [selectedTags, setSelectedTags] = useState([]);

  const groupResourcesByTag = (resources) => {
    const grouped = {};
    resources.forEach(resource => {
      (resource.newTags || []).forEach(tag => { // Default to empty array
        if (!grouped[tag]) {
          grouped[tag] = [];
        }
        grouped[tag].push(resource);
      });
    });
    return grouped;
  };

  const groupedResources = groupResourcesByTag(resources);

  const getLimitedResources = (groupedResources, selectedTags, currentResourceId) => {
    let resourceMap = new Map();

    if (selectedTags.length === 0) {
      Object.keys(groupedResources).forEach(tag => {
        groupedResources[tag].forEach(resource => {
          if (resource.id !== currentResourceId) {
            resourceMap.set(resource.id, resource);
          }
        });
      });
    } else {
      selectedTags.forEach(tag => {
        if (groupedResources[tag]) {
          groupedResources[tag].forEach(resource => {
            if (resource.id !== currentResourceId) {
              resourceMap.set(resource.id, resource);
            }
          });
        }
      });
    }

    const filteredResources = Array.from(resourceMap.values());

    return filteredResources.slice(0, 16);
  };

  const limitedResources = getLimitedResources(groupedResources, selectedTags, resource.id || "");

  const swiperRef = useRef(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const goNext = () => {
    if (swiperRef.current && !swiperRef.current.isEnd) {
      swiperRef.current.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef.current && !swiperRef.current.isBeginning) {
      swiperRef.current.slidePrev();
    }
  };

  const checkSwiperState = () => {
    if (swiperRef.current) {
      setIsBeginning(swiperRef.current.isBeginning);
      setIsEnd(swiperRef.current.isEnd);
    }
  };

  useEffect(() => {
    checkSwiperState();
    swiperRef.current?.on('slideChange', checkSwiperState);
    return () => {
      swiperRef.current?.off('slideChange', checkSwiperState);
    };
  }, []);

  const handleClick = (e, tag) => {
    e.preventDefault();
    setSelectedTags((prevTags) => {
      if (prevTags.includes(tag)) {
        return prevTags.filter((selectedTag) => selectedTag !== tag);
      } else {
        return [...prevTags, tag];
      }
    });
  };

  const handleViewAllClick = (e) => {
    e.preventDefault();
    setSelectedTags([]);
  };

  // Define class based on the title being "Untitled Resource"
  const resourcePageClassName = `resourcesCenterPage ${resourceTitle === "Untitled Resource" ? "no-fr-title" : ""}`;


  return (
    <div className={resourcePageClassName}>
      <h2 className='no-post-error' style={{display: 'none'}}>
        Ce contenu n'existe pas en français.
      </h2>
      <Layout>
        <Seo title={resource.title || "Resource"} />
        <div className="resourceDetailsContentWrapper">
          <div className="resourceDetailsHero">
            <div className="container">
              <Link className="backToResourcesBtn" to="/resources">
                <span className="leftArrowCaret">&#9664;</span>
                <span className="en-only">Back to Resource Hub</span>
                <span className="fr-only">Retour au centre de ressources</span>
              </Link>
            </div>
          </div>
          <div className="resourceDetailsContent">
            <div className="container">
              <div className="resourceDetailsHeroCard">
                <div className="pillsCategoryWrapper">
                  {(resource.newTags || []).map((tag, index) => (
                    <span key={index} className="pillsCategory">{tag}</span>
                  ))}
                </div>
                <img src={resource.image?.file?.url || ""} alt={resource.title || "Resource"} />
                <h1>{resource.title || "Untitled Resource"}</h1>
                <p className="lead">{resource.excerpt?.excerpt || "No description available."}</p>
                <span className="resourceCOMMBCallout">
                  <span className="en-only">Canadian Out-of-Home Marketing and Measurement Bureau</span>
                  <span className="fr-only">Bureau canadien du marketing et de l'évaluation d'affichage</span>
                </span>
                <a href={resource.fileUrl || "#"} className="resourceDownloadBtn" target="_blank" rel="noopener noreferrer">
                  <span className="en-only">Download</span>
                  <span className="fr-only">Télécharger</span>
                </a>
              </div>
              <div className="resourceDetailsMainContent">
                {resource.content ? renderRichText(resource.content) : <p>No content available.</p>}
              </div>
              <a href={resource.fileUrl || "#"} className="resourceDownloadBtn" target="_blank" rel="noopener noreferrer">
                <span className="en-only">Download</span>
                <span className="fr-only">Télécharger</span>
              </a>
            </div>
          </div>
        </div>
        <div className="relatedResourcesWrapper">
          <div className="container">
            <h3>
              <span className="en-only">Related Resources</span>
              <span className="fr-only">Ressources Connexes</span>
            </h3>
            <div className="relatedTagButtonsWrapper en-only">
              {Array.isArray(resources[0]?.tags) && resources[0]?.tags.length > 0 && resources[0]?.tags.map((tag, index) => (
                <a key={index} href="#" onClick={(e) => handleClick(e, tag)} 
                  className={selectedTags.includes(tag) ? 'active' : ''}>
                  {tag}
                </a>
              ))}
              <a href="#" className={`viewAllResourcesBtn ${selectedTags.length === 0 ? 'active' : ''}`} onClick={handleViewAllClick}>
                {locale === 'fr-CA' ? 'Voir tout' : 'View All'}
              </a>
            </div>
            <div className="relatedResourceCardGrid">
              <button onClick={goPrev} className="relatedResourcePrevBtn" disabled={isBeginning}>◀</button>
              <button onClick={goNext} className="relatedResourceNextBtn" disabled={isEnd}>▶</button>
              <Swiper
                modules={[Navigation]}
                spaceBetween={15}
                slidesPerView={4}
                onSwiper={(swiper) => (swiperRef.current = swiper)}
              >
                {limitedResources?.length > 0 && limitedResources.filter(r => r.image !== null).map((item, i) => (
                  <SwiperSlide key={i}>
                    <div className="resourceGridCard">
                      <Link to={`/resource-centre/${item.slug}`}>
                        <img src={item.image?.file?.url || ""} alt={item.title || "Resource"} />
                      </Link>
                      <span className="dateImgCard">
                        {dayDate(item.date || "")}
                        <small>{monthYearDate(item.date || "")}</small>
                      </span>
                      <div className="resourceCardDetails">
                        {(item.newTags || []).map((tag, index) => (
                          <span key={index} className="pillsCategory">{tag}</span>
                        ))}
                        <h3>
                          <Link to={`/resource-centre/${item.slug}`}>
                            {item.title || "Untitled"}
                          </Link>
                        </h3>
                        <hr />
                        <div className="resourceCardButtonWrapper">
                          <Link to={`/resource-centre/${item.slug}`} className="resourceMoreDetailsBtn">
                            <span className="en-only">More Details</span>
                            <span className="fr-only">Plus de détails</span>
                          </Link>
                          <a href={item.fileUrl || "#"} className="resourceDownloadBtn" target="_blank" rel="noopener noreferrer">
                            <span className="en-only">Download</span>
                            <span className="fr-only">Télécharger</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default ResourceDetail;

export const query = graphql`
  query GetResource($slug: String, $language: String) {
    contentfulResourceCentre(slug: { eq: $slug }, node_locale: { eq: $language }) {
      id
      title
      excerpt {
        excerpt
      }
      tags
      newTags
      fileUrl
      content {
        raw
      }
      image {
        file {
          url
        }
      }
      node_locale
    }
    allContentfulResourceCentre(filter: { node_locale: { eq: $language } }) {
      nodes {
        id
        title
        slug
        tags
        newTags
        fileUrl
        image {
          file {
            url
          }
        }
      }
    }
  }
`;
